export const namespaced = true

export const state = {
  submitting: false,
  loading: false,
  sendingToConservazione: false,
  sidebarOpened: false,
  alerts: [],
  snackbar: { show: false },
  search: null,
  // page: null,
}

export const mutations = {
  SUBMITTING_REQUEST (state, submitting) {
    state.submitting = submitting
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_SENDING_TO_CONSERVAZIONE (state, sendingToConservazione) {
    state.sendingToConservazione = sendingToConservazione
  },
  SET_SIDEBAR_STATUS (state, bolOpen) {
    state.sidebarOpened = bolOpen
  },
  ADD_ALERT (state, alertData) {
    state.alerts.push(alertData)
  },
  CLEAR_ALERTS (state) {
    state.alerts = []
  },
  REMOVE_ALERT (state, alertId) {
    state.alerts = state.alerts.filter(function (alert) {
      return alert.id !== alertId
    })
  },
  SET_SEARCH (state, search) {
    state.search = search
  },
  // SET_PAGE(state, page) {
  //   state.page = page
  // },
  SET_SNACKBAR (state, data) {
    const defaultSnackbarOptions = {
      text: null,
      color: null,
      multiLine: true,
      top: true,
      left: false,
      right: false,
    }
    state.snackbar = { ...defaultSnackbarOptions, ...data }
  },
}

export const actions = {
  toggle_sidebar ({ commit, state }) {
    commit('SET_SIDEBAR_STATUS', !state.sidebarOpened)
  },
  submitting_request ({ commit }, submitting) {
    commit('SUBMITTING_REQUEST', submitting)
  },
  set_loading ({ commit }, loading) {
    commit('SET_LOADING', loading)
  },
  set_sending_to_conservazione ({ commit }, sendingToConservazione) {
    commit('SET_SENDING_TO_CONSERVAZIONE', sendingToConservazione)
  },
  setSidebarStatus ({ commit }, bolOpen) {
    commit('SET_SIDEBAR_STATUS', bolOpen)
  },
  addAlert ({ commit }, alertData) {
    if (!['success', 'info', 'warning', 'error'].includes(alertData.type)) return
    if (!!alertData.key && !!alertData.message) return
    // Make a random alphanumeric code size 10, like: 67s4o8fjg0
    alertData.id = (Math.random() + 1).toString(36).substring(2, 12)
    commit('ADD_ALERT', alertData)
  },
  clearAlerts ({ commit }) {
    commit('CLEAR_ALERTS')
  },
  replaceAlert ({ dispatch }, alertData) {
    dispatch('clearAlerts')
    dispatch('addAlert', alertData)
  },
  removeAlert ({ commit }, alertId) {
    commit('REMOVE_ALERT', alertId)
  },
  setSearch ({ commit }, search) {
    commit('SET_SEARCH', search)
  },
  // setPage({ commit }, page) {
  //   commit('SET_PAGE', page)
  // },
  setSnackbar ({ commit }, data) {
    if (data.show == undefined) {
      data.show = true
    }
    commit('SET_SNACKBAR', data)
  },
}
