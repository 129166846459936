import Vue from 'vue'
import storeOptions from '../store_options'

export const namespaced = true

export const state = {
  dossiers: [],
  dossier: {},
  dossierPageCount: null,
  areDossiersChangedAfterPull: null,
  currentView: 'all',
  search: {
    id: '',
    text: '',
    states: [],
    creator_id: '',
    fromDate: '',
    toDate: '',
  },
}

export const mutations = {
  SET_CURRENT_VIEW (state, value) {
    state.currentView = value
  },
  SET_DOSSIERS_SEARCH (state, search) {
    state.search = search
  },
  SET_DOSSIERS (state, dossiers) {
    state.dossiers = dossiers
  },
  SET_DOSSIER (state, dossier) {
    state.dossier = dossier
  },
  UPDATE_DOSSIER_ATTRIBUTES (state, attributes) {
    if (isEmptyObject(state.dossier) && state.dossiers.length > 0) {
      state.dossier = state.dossiers.find(obj => obj.id == attributes.id) || {}
    }
    console.log(`Refresh dossier ${attributes.id} from state ${state.dossier.state} to ${attributes.state}`)
    Object.entries(attributes).forEach(([key, value]) => {
      // console.log(`- ${key} = ${value}`)
      state.dossier[key] = value
    })
  },
  UPDATE_SIGN_POINTS_IN_DOSSIER (state, signPoints) {
    console.log("UPDATE_SIGN_POINTS_IN_DOSSIER", signPoints)
    for (var i = 0; i < state.dossier.documents.length; i++) {
      var document = state.dossier.documents[i]
      if (document.label == "unsigned_document") {
        console.log("FOUND UNSIGNED DOCUMENT AT INDEX " + i)
        state.dossier.documents[i].sign_points = signPoints
        return
      }
    }
    console.log("UNSIGNED DOCUMENT NOT FOUND. NO UPDATES.")
  },
  UPDATE_SIGN_POINT_PERMISSIONS_IN_DOSSIER (state, signPointPermissions) {
    console.log("UPDATE_SIGN_POINT_PERMISSIONS_IN_DOSSIER", signPointPermissions)
    for (var i = 0; i < state.dossier.documents.length; i++) {
      var document = state.dossier.documents[i]
      if (document.label == "unsigned_document") {
        console.log("FOUND UNSIGNED DOCUMENT AT INDEX " + i)
        state.dossier.documents[i].sign_point_permissions = signPointPermissions
        return
      }
    }
    console.log("UNSIGNED DOCUMENT NOT FOUND. NO UPDATES.")
  },
  // After updates use this mutation to refresh the single item into the cache
  REFRESH_DOSSIER_IN_DOSSIERS (state, dossier = state.dossier) {
    const indexToReplace = state.dossiers.findIndex(d => d.id == dossier.id)
    if (indexToReplace != -1) {
      console.log(' - replace old with newest, id', dossier.id, "at index", indexToReplace)
      // state.dossiers[indexToReplace] = dossier
      state.dossiers.splice(indexToReplace, 1, dossier)
      // this is for vue reaction
      // state.dossiers.push({})
      // state.dossiers.splice(-1, 1)
    } else {
      // Add new item at the beginning
      if (!isEmptyObject(dossier)) state.dossiers.unshift(dossier)
    }
    // console.log('  - replaced! New items:', state.dossiers.length)
  },
  // After updates a recipient use this mutation to refresh the single item into the cache
  REFRESH_RECIPIENT_IN_DOSSIER (state, { dossier = state.dossier, recipient }) {
    if (isEmptyObject(dossier)) {
      dossier = state.dossiers.find(obj => obj.id == recipient.dossier_id) || {}
      state.dossier = dossier
    }
    console.log('Refresh recipient, currently items:', dossier.recipients?.length)
    if (!dossier.recipients) return
    const indexToReplace = dossier.recipients.findIndex(obj => obj.id == recipient.id)
    if (indexToReplace != -1) {
      console.log(' - replace old with newest, id', recipient.id)
      dossier.recipients.splice(indexToReplace, 1, recipient)
      // PLEASE NOTE: Changing one element in recipients array does NOT fire any reaction watching dossier object.
      // To obtain a reaction you have to watch recipients method.
    } else {
      console.log(`  - recipient ${recipient.id} not found in dossier ${dossier.id}!`)
    }
  },
  // ADD_DOSSIER(state, dossier) {
  //   state.dossiers.push(dossier)
  // },
  REMOVE_DOSSIER (state, id) {
    const indexToRemove = state.dossiers.findIndex(dossier => dossier.id == id)
    if (indexToRemove != -1) {
      state.dossiers.splice(indexToRemove, 1)
    }
  },
  SET_PAGE_COUNT (state, value) {
    state.dossierPageCount = value
  },
  SET_DOSSIERS_CHANGED_AFTER_PULL (state, value) {
    state.areDossiersChangedAfterPull = value
  },
}

export const actions = {
  fetchDossiers ({ commit, dispatch, rootState }, params = { page: 1, per_page: 10, creator_id: null, sort_by: null, sort_desc: null, id: null, text: null, state: null, from_date: null, to_date: null }) {
    // console.log(params)
    return new Promise((resolve, reject) => {
      dispatch('layout/set_loading', true, { root: true })
      // Array of objects  to build the "in" array, for example:
      // from params: 'q[recipients_state_eq]': 35, 'q[recipients_identification_state_in][]': [10, 40, 60],
      // to url: ?page=1&per_page=10&q[recipients_state_eq]=35&q[recipients_identification_state_in][]=10&q[recipients_identification_state_in][]=40&q[recipients_identification_state_in][]=60
      // to sql: ...AND ("recipients"."state" = 35 AND "recipients"."identification_state" IN (10, 40, 60))
      const arrayIn = []
      // console.log(`fetchDossiers rootState ${rootState.application.urls.dossiers_index}`)
      const arrayParams = Object.keys(params)
        .filter(p => params[p] != null && params[p] != '')
        .map(p => typeof params[p] === 'object' && params[p].constructor === Array ? arrayIn.push({ [p]: params[p] }) && null : `${p}=${params[p]}`)
        .filter(p => p != null)
      // let url = `${rootState.application.urls.dossiers_index}?page=${params.page}&per_page=${params.perPage}`
      let url = rootState.application.urls.dossiers_index
      if (arrayParams.length > 0) url += `?${arrayParams.join('&')}`
      // console.log(`fetchDossiers 1. url ${url}`)
      if (arrayIn.length > 0) {
        arrayIn.forEach(obj => {
          Object.entries(obj).forEach(([key, values]) => {
            const strValues = values.map(value => `${key}=${value}`)
            url += `&${strValues.join('&')}`
          })
        })
      }
      console.log(`fetchDossiers 2. url ${url}`)
      Vue.http.get(url, null, {
        responseType: 'json',
        timeout: storeOptions.timeout,
      }).then(response => {
        const result = response.data
        commit('SET_DOSSIERS', result.dossiers)
        commit('SET_PAGE_COUNT', result.page_count)
        commit('SET_DOSSIERS_CHANGED_AFTER_PULL', false)
        dispatch('layout/set_loading', false, { root: true })
        resolve({dossiers: result.dossiers, pageCount: result.page_count, totalCount: result.total_count})
      }).catch(error => {
        dispatch('layout/set_loading', false, { root: true })
        console.log('fetchDossiers There was an error:', error.response ? error.response : error)
        reject(error)
      })
    })
  },
  fetchDossier ({ commit, dispatch, getters, rootState }, { id, fresh = false }) {
    return new Promise((resolve, reject) => {
      console.log(`dossier/fetchDossier id ${id} fresh ${fresh}`)
      let dossier
      if (!fresh) {
        dossier = getters.getDossierById(id)
      }
      if (dossier) {
        console.log(`  - dossier retrieved from cache`, dossier)
        // Rimpiazziamo dossier con quello dell'id ricercato
        if (!Object.hasOwn(dossier, "conservazione_digitale_type")) {
          dossier.conservazione_digitale_type = null
        }
        commit('SET_DOSSIER', dossier)
        resolve(dossier)
      } else {
        dispatch('layout/set_loading', true, { root: true })
        const url = rootState.application.urls.dossier_show.replace(':id', id)
        return Vue.http.get(url, null, {
          responseType: 'json',
          timeout: storeOptions.timeout,
        }).then(response => {
          dossier = response.data
          if (!Object.hasOwn(dossier, "conservazione_digitale_type")) {
            dossier.conservazione_digitale_type = null
          }
          commit('SET_DOSSIER', dossier)
          commit('REFRESH_DOSSIER_IN_DOSSIERS', dossier)
          dispatch('layout/set_loading', false, { root: true })
          resolve(dossier)
        })
          .catch(error => {
            console.log('fetchDossier There was an error:', error.response ? error.response : error)
            dispatch('layout/set_loading', false, { root: true })
            reject(error)
          })
      }
    })
  },
  fetchDossiersWithConservazioneErrors ({ commit, dispatch, rootState }, params = { page: 1, per_page: 10, creator_id: null, sort_by: null, sort_desc: null, id: null, text: null, state: null, from_date: null, to_date: null }) {
    return new Promise((resolve, reject) => {
      dispatch('layout/set_loading', true, { root: true })
      const arrayIn = []
      const arrayParams = Object.keys(params)
        .filter(p => params[p] != null && params[p] != '')
        .map(p => typeof params[p] === 'object' && params[p].constructor === Array ? arrayIn.push({ [p]: params[p] }) && null : `${p}=${params[p]}`)
        .filter(p => p != null)
      let url = rootState.application.urls.dossiers_conservazione_errors
      if (arrayParams.length > 0) url += `?${arrayParams.join('&')}`
      if (arrayIn.length > 0) {
        arrayIn.forEach(obj => {
          Object.entries(obj).forEach(([key, values]) => {
            const strValues = values.map(value => `${key}=${value}`)
            url += `&${strValues.join('&')}`
          })
        })
      }
      console.log(`fetchDossiersWithConservazioneErrors 2. url ${url}`)
      Vue.http.get(url, null, {
        responseType: 'json',
        timeout: storeOptions.timeout,
      }).then(response => {
        const result = response.data
        commit('SET_DOSSIERS', result.dossiers)
        commit('SET_PAGE_COUNT', result.page_count)
        commit('SET_DOSSIERS_CHANGED_AFTER_PULL', false)
        dispatch('layout/set_loading', false, { root: true })
        resolve({dossiers: result.dossiers, pageCount: result.page_count, totalCount: result.total_count})
      }).catch(error => {
        dispatch('layout/set_loading', false, { root: true })
        console.log('fetchDossiersWithConservazioneErrors There was an error:', error.response ? error.response : error)
        reject(error)
      })
    })
  },
  create ({ commit, dispatch, rootState }, params) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossiers_index
      const formData = formDataFromParams(params)
      // console.log('dossier/create formData', formData)
      Vue.http.post(url, formData, { timeout: storeOptions.timeout })
        .then(response => {
          const dossier = response.body
          console.log(`create dossier`, dossier)
          commit('SET_DOSSIER', dossier)
          commit('REFRESH_DOSSIER_IN_DOSSIERS', dossier)
          resolve(dossier)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  update ({ commit, dispatch, rootState }, { dossier_id, params }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossier_show.replace(':id', dossier_id)
      const formData = formDataFromParams(params)
      Vue.http.put(url, formData, { timeout: storeOptions.timeout })
        .then(response => {
          const dossier = response.body
          // Per qualche strano motivo, forse un bug, la risposta ha recipient doppi. Si verifica dopo l'aggiunta di
          // nuovi recipient durante la modifica
          const recipients = dossier.recipients
          const recipientIds = recipients.map(r => r.id)
          const uniqIds = new Set(recipientIds)
          console.log(`update dossier`, dossier, uniqIds, recipients)
          if (uniqIds.size < recipients.length) {
            console.log(`- remove duplicated ids ${recipientIds}`)
            dossier.recipients = recipients.slice(0, uniqIds.size)
          }

          commit('SET_DOSSIER', dossier)
          commit('REFRESH_DOSSIER_IN_DOSSIERS', dossier)
          resolve(dossier)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  updateUnsignedDocument ({ commit, dispatch, rootState }, { dossier_id, params, end_draft }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossier_show.replace(':id', dossier_id)
      Vue.http.put(url, { unsigned_document: params, end_draft }, { timeout: storeOptions.timeout })
        .then(response => {
          const dossier = response.body
          console.log("dossier.js updateUnsignedDocument", dossier)
          commit('SET_DOSSIER', dossier)
          commit('REFRESH_DOSSIER_IN_DOSSIERS', dossier)
          resolve(dossier)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  delete ({ commit, dispatch, rootState }, dossier_id) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossier_show.replace(':id', dossier_id)
      Vue.http.delete(url)
        .then(response => {
          console.log(`delete dossier`, dossier_id)
          commit('REMOVE_DOSSIER', dossier_id)
          resolve(response)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  cancel ({ commit, dispatch, rootState }, { dossier_id, set } ) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossier_cancel.replace(':id', dossier_id)
      Vue.http.put(url, { set })
        .then(response => {
          console.log(`cancel dossier`, dossier_id)
          commit('REMOVE_DOSSIER', dossier_id)
          resolve(response)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  costs ({ commit, dispatch, rootState }, { dossier_id }) {
    console.log("dossier/costs called");
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossier_costs.replace(':id', dossier_id)
      Vue.http.get(url, null)
        .then(response => {
          console.log(resolve)
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  getCanceledDossiers ({ commit, dispatch, rootState }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossiers_get_canceled
      Vue.http.get(url, null)
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  updateRecipientInDossier ({ commit }, recipient) {
    return new Promise((resolve, reject) => {
      commit('REFRESH_RECIPIENT_IN_DOSSIER', { recipient })
      if (recipient.dossier) {
        console.log(`update dossier ${recipient.dossier.id}`)
        commit('UPDATE_DOSSIER_ATTRIBUTES', recipient.dossier)
      }
      commit('REFRESH_DOSSIER_IN_DOSSIERS')
      resolve(true)
    })
  },
  updateSignPointsInDossier ({ commit }, signPoints) {
    return new Promise((resolve, reject) => {
      commit('UPDATE_SIGN_POINTS_IN_DOSSIER', signPoints)
      commit('REFRESH_DOSSIER_IN_DOSSIERS')
      resolve(true)
    })
  },
  updateSignPointPermissionsInDossier ({ commit }, signPointPermissions) {
    return new Promise((resolve, reject) => {
      commit('UPDATE_SIGN_POINT_PERMISSIONS_IN_DOSSIER', signPointPermissions)
      commit('REFRESH_DOSSIER_IN_DOSSIERS')
      resolve(true)
    })
  },
  updateDossierInDossiers ({ commit }, dossier) {
    return new Promise((resolve, reject) => {
      console.log(`updateDossierInDossiers ${dossier.id}`, dossier)
      commit('UPDATE_DOSSIER_ATTRIBUTES', dossier)
      commit('REFRESH_DOSSIER_IN_DOSSIERS')
      resolve(true)
    })
  },
  setDossiersChanged ({ commit, getters }, { isRemoved, dossier }) {
    return new Promise((resolve, reject) => {
      const isPresent = getters.getDossierById(dossier.id)
      if (isRemoved ? isPresent : !isPresent) {
        console.log(`setDossiersChanged ${dossier.id}`)
        commit('SET_DOSSIERS_CHANGED_AFTER_PULL', true)
      }
      resolve(true)
    })
  },
  favoriteDossier ({ commit, dispatch, rootState }, { dossier, operation }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossier_favorite.replace(':id', dossier.id)
      Vue.http.post(url, { operation })
        .then(response => {
          const new_dossier = { ...dossier, my_favorite: !dossier.my_favorite }
          commit('SET_DOSSIER', new_dossier)
          dispatch('updateDossierInDossiers', new_dossier)
          resolve(new_dossier)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },

  setDossierExpirationDay({ commit, dispatch, rootState }, { dossier_id, params = { expires_at: null } }) {
    const formData = new FormData()
    formData.append('expires_at', params.expires_at)
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.dossier_expiration.replace(':id', dossier_id)
      Vue.http.put(url, formData)
        .then(response => {
          /* const new_dossier = { ...dossier, my_favorite: !dossier.my_favorite }
          commit('SET_DOSSIER', new_dossier)
          dispatch('updateDossierInDossiers', new_dossier)
          resolve(new_dossier) */
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },

  setCurrentView ({ commit }, currentView) {
    commit('SET_CURRENT_VIEW', currentView)
  },
  setDossiersSearch ({ commit }, search) {
    commit('SET_DOSSIERS_SEARCH', search)
  },
  sendDossierToConservazione ({ commit, dispatch, rootState }, dossier_id) {
    console.log(`sendDossierToConservazione ${dossier_id}`)
    return new Promise((resolve, reject) => {
      console.log(`dossier/sendDossierToConservazione id ${dossier_id}`)
      dispatch('layout/set_sending_to_conservazione', true, { root: true })
      const url = rootState.application.urls.dossier_send_to_conservazione_digitale.replace(':id', dossier_id)
      return Vue.http.put(url, null, {
        responseType: 'json',
        timeout: storeOptions.timeout,
      }).then(response => {
        console.log("sendDossierToConservazione response", response)
        const dossier = response.data.dossier ? response.data.dossier : response.data
        commit('SET_DOSSIER', dossier)
        // If we refresh we trigger adding this dossier to the draft dossiers minitable in the dashboard
        // and it the dossier updates in the list of dossiers anyway.
        //commit('REFRESH_DOSSIER_IN_DOSSIERS', dossier)
        dispatch('layout/set_sending_to_conservazione', false, { root: true })
        resolve(dossier)
      })
        .catch(error => {
          console.log(`sendDossierToConservazione id ${dossier_id} There was an error:`, error.response ? error.response : error)
          dispatch('layout/set_sending_to_conservazione', false, { root: true })
          reject(error)
        })
    })
  },
}

export const getters = {
  getDossierById: state => id => {
    return state.dossiers.find(dossier => dossier.id == id)
  },
}

function formDataFromParams (params) {
  const formData = new FormData()

  formData.append('type', params.type)

  if (params.unsigned_document_file) formData.append('unsigned_document[file_content]', params.unsigned_document_file)

  for (let i = 0; i < params.recipients.length; i++) {
    if (params.recipients[i].id) formData.append(`recipients[${i}][id]`, params.recipients[i].id)
    if (params.recipients[i].signer_id) formData.append(`recipients[${i}][signer_id]`, params.recipients[i].signer_id)
    formData.append(`recipients[${i}][signer_type]`, params.recipients[i].signer_type)
    formData.append(`recipients[${i}][first_name]`, params.recipients[i].first_name)
    formData.append(`recipients[${i}][last_name]`, params.recipients[i].last_name)
    formData.append(`recipients[${i}][email]`, params.recipients[i].email)
    formData.append(`recipients[${i}][phone_prefix]`, params.recipients[i].phone_prefix)
    formData.append(`recipients[${i}][phone_number]`, params.recipients[i].phone_number)
    formData.append(`recipients[${i}][language]`, params.recipients[i].language)
    if (params.recipients[i].fiscal_code) formData.append(`recipients[${i}][fiscal_code]`, params.recipients[i].fiscal_code)
    if (params.recipients[i].birthdate) formData.append(`recipients[${i}][birthdate]`, params.recipients[i].birthdate)
    if (params.recipients[i].birth_country_id) formData.append(`recipients[${i}][birth_country_id]`, params.recipients[i].birth_country_id)
    if (params.recipients[i].birth_region_id) formData.append(`recipients[${i}][birth_region_id]`, params.recipients[i].birth_region_id)
    if (params.recipients[i].birth_city_id) formData.append(`recipients[${i}][birth_city_id]`, params.recipients[i].birth_city_id)
    if (params.recipients[i].citizenship_id) formData.append(`recipients[${i}][citizenship_id]`, params.recipients[i].citizenship_id)
    if (params.recipients[i].residence_country_id) formData.append(`recipients[${i}][residence_country_id]`, params.recipients[i].residence_country_id)
    if (params.recipients[i].residence_region_id) formData.append(`recipients[${i}][residence_region_id]`, params.recipients[i].residence_region_id)
    if (params.recipients[i].residence_city_id) formData.append(`recipients[${i}][residence_city_id]`, params.recipients[i].residence_city_id)
    if (params.recipients[i].residence_address) formData.append(`recipients[${i}][residence_address]`, params.recipients[i].residence_address)
    if (params.recipients[i].gender) {
      const fixed_gender = params.recipients[i].gender == 'male'
        ? 'M'
        : params.recipients[i].gender == 'female' ? 'F' : params.recipients[i].gender
      formData.append(`recipients[${i}][gender]`, fixed_gender)
    }
    if (params.recipients[i].document_type) formData.append(`recipients[${i}][document_type]`, params.recipients[i].document_type)
    if (params.recipients[i].document_number) formData.append(`recipients[${i}][document_number]`, params.recipients[i].document_number)
    if (params.recipients[i].document_expire_on) formData.append(`recipients[${i}][document_expire_on]`, params.recipients[i].document_expire_on)
    if (params.recipients[i].document_issued_on) formData.append(`recipients[${i}][document_issued_on]`, params.recipients[i].document_issued_on)
    if (params.recipients[i].document_issued_country_id) formData.append(`recipients[${i}][document_issued_country_id]`, params.recipients[i].document_issued_country_id)
    if (params.recipients[i].document_issued_region_id) formData.append(`recipients[${i}][document_issued_region_id]`, params.recipients[i].document_issued_region_id)
    if (params.recipients[i].document_issued_city_id) formData.append(`recipients[${i}][document_issued_city_id]`, params.recipients[i].document_issued_city_id)
    if (params.recipients[i].recognized_de_visu_on) formData.append(`recipients[${i}][recognized_de_visu_on]`, params.recipients[i].recognized_de_visu_on)
    formData.append(`recipients[${i}][doc_stored_by_customer]`, params.recipients[i].doc_stored_by_customer)
    if (params.recipients[i].conservazione_digitale_type) formData.append(`recipients[${i}][conservazione_digitale_type]`, params.recipients[i].conservazione_digitale_type)
    if (params.recipients[i].face_document_image_blob_id) formData.append(`recipients[${i}][face_document_image_blob_id]`, params.recipients[i].face_document_image_blob_id)
    if (params.recipients[i].face_document_image) formData.append(`recipients[${i}][face_document_image]`, params.recipients[i].face_document_image)
    if (params.recipients[i].front_document_image_blob_id) formData.append(`recipients[${i}][front_document_image_blob_id]`, params.recipients[i].front_document_image_blob_id)
    if (params.recipients[i].front_document_image) formData.append(`recipients[${i}][front_document_image]`, params.recipients[i].front_document_image)
    if (params.recipients[i].back_document_image_blob_id) formData.append(`recipients[${i}][back_document_image_blob_id]`, params.recipients[i].back_document_image_blob_id)
    if (params.recipients[i].back_document_image) formData.append(`recipients[${i}][back_document_image]`, params.recipients[i].back_document_image)
    if (params.recipients[i]._destroy) formData.append(`recipients[${i}][_destroy]`, params.recipients[i]._destroy)
  }

  if (params.state) formData.append('state', params.state)
  formData.append('duplicate_documents_allowed', params.duplicate_documents_allowed)
  formData.append('doc_stored_by_customer', params.doc_stored_by_customer)
  formData.append('conservazione_digitale_type', params.conservazione_digitale_type == "null" ? null : params.conservazione_digitale_type)
  formData.append('timestamp', ((Date.now() / 1000 | 0) + 10000).toString())
  formData.append('source_type', 'web')
  return formData
}

function isEmptyObject (obj) {
  // return Object.keys(obj ?? {}).length === 0
  let name
  for (name in obj) { return false }
  return true
}

function setRefreshAfterUpdate (isDossierPresentOrAbsent, dossier_id) {
  const result = getters.getDossierById(dossier_id)
  if (isDossierPresentOrAbsent ? result : !result) this.anyDossierUpdated = true
}
