import Vue from 'vue'
import router from '../../router'

export const namespaced = true

export const state = {
  basePath: '',
  urls: {},
  authToken: localStorage.getItem('authToken') || null,
}

export const mutations = {
  LOGIN_SUCCESS (state, authToken) {
    state.authToken = authToken
  },
  LOGOUT (state) {
    state.authToken = null
    localStorage.removeItem('authToken')
    Vue.http.headers.common.Authorization = null
  },
}

export const actions = {
  loginFromAuthToken ({ commit, dispatch, rootState }, authToken) {
    return new Promise((resolve, reject) => {
      if (authToken) {
        localStorage.setItem('authToken', authToken)
        Vue.http.headers.common.Authorization = 'Bearer ' + authToken
        commit('LOGIN_SUCCESS', authToken)
        resolve(authToken)
      } else {
        reject(new Error('missing auth token'))
      }
    })
  },
  login ({ commit, dispatch, rootState }, credentials) {
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.login, credentials)
        .then(response => {
          const body = response.body
          // Save authentication token
          const authToken = body.auth_token
          if (credentials.rememberMe) localStorage.setItem('authToken', authToken)
          Vue.http.headers.common.Authorization = 'Bearer ' + authToken
          commit('LOGIN_SUCCESS', authToken)

          // Save user attributes and avoid a new call
          const userAttributes = body.user
          if (userAttributes) {
            dispatch('profile/setUser', userAttributes, { root: true })
          } else {
          // ...if the user data is not received from the authenticate, a specific call is attempted
            dispatch('profile/fetchUser', null, { root: true })
          }

          // Save organization attributes and avoid a new call
          const organizationAttributes = body.organization
          if (organizationAttributes) {
            dispatch('organization/setOrganization', organizationAttributes, { root: true })
          } else {
          // ...if the organization data is not received from the authenticate, a specific call is attempted
            dispatch('organization/fetchOrganization', null, { root: true })
          }

          const subscriptionAttributes = body.last_subscription
          if (subscriptionAttributes) {
            dispatch('subscription/setSubscription', subscriptionAttributes, { root: true })
          } else {
          // ...if the subscription data is not received from the authenticate, a specific call is attempted
            dispatch('subscription/fetchSubscription', null, { root: true })
          }
          resolve(body)
        }, error => {
          commit('LOGOUT')
          reject(error)
        })
    })
  },
  logoutBackend ({ commit, dispatch, rootState }, { disconnect_all = false }) {
    const params = {}
    if (disconnect_all) params.all = true
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.logout, params)
        .then(response => {
          dispatch('logout', {})
          resolve(response.body)
        }, error => {
          reject(error)
        })
    })
  },
  logout ({ commit, dispatch, rootState }, { force = false }) {
    commit('LOGOUT')
    dispatch('organization/clearOrganization', null, { root: true })
    dispatch('statistics/clearStatistics', null, { root: true })
    this.$cable.unsubscribe('OrganizationsChannel')
    //alert = force === true ? { type: 'error', key: 'force_logout' } : { type: 'success', key: 'logout' }
    router.push({ name: 'login' })
    setTimeout(() => {
      location.reload()
      //dispatch('layout/replaceAlert', alert, { root: true })
    }, 300)
  },
  signUp ({ commit, rootState }, { user, customer, otpservice_enrollment }) {
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.signUp,
                    { user, customer, otpservice_enrollment }).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  passwordReset ({ commit, dispatch, rootState }, email) {
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.passwordReset, {
        user: { email },
      }).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  customerPasswordReset ({ rootState }) {
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.customerPasswordReset)
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  newPassword ({ commit, dispatch, rootState }, data) {
    return new Promise((resolve, reject) => {
      Vue.http.put(rootState.application.urls.passwordReset, {
        user: {
          password: data.password,
          reset_password_token: data.token,
        },
      }).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  sendConfirmationEmail ({ commit, dispatch, rootState }, email) {
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.confirmationEmail, {
        user: { email },
      }).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
}

export const getters = {
  isLoggedIn: state => {
    return !!state.authToken
  },
}
