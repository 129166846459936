import Vue from 'vue'
import Vuetify from '../../plugins/vuetify'
import router from '../../router'

export const namespaced = true

export const state = {
  conservazioneDigitale: {},
}

export const mutations = {
  SET_CONSERVAZIONE_DIGITALE_A_NORMA (state, conservazioneDigitale) {
    state.conservazioneDigitale = conservazioneDigitale
  },
}

export const actions = {
  test ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      const formData = formDataFromParams(params)
      Vue.http.post(rootState.application.urls.conservazioneDigitaleTest, formData)
        .then(response => {
          console.log(JSON.stringify(response))
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  remove ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      Vue.http.delete(rootState.application.urls.conservazioneDigitaleDelete)
        .then(response => {
          commit('SET_CONSERVAZIONE_DIGITALE_A_NORMA', response.body);
          commit('organization/SET_CONSERVAZIONE_DIGITALE_A_NORMA', response.body, {root: true});
          resolve(response);
        }, error => {
          reject(error);
        })
    });
  },
  update ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      const formData = formDataFromParams(params)
      Vue.http.put(rootState.application.urls.conservazioneDigitale, formData)
        .then(response => {
          commit('SET_CONSERVAZIONE_DIGITALE_A_NORMA', response.body)
          commit('organization/SET_CONSERVAZIONE_DIGITALE_A_NORMA', response.body, {root: true})
          router.push({name: 'showConservazioneDigitale'})
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
}

export const getters = {
  username: (state, getters) => {
    return state.conservazioneDigitale.username
  },
  password: (state, getters) => {
    return state.conservazioneDigitale.password
  },
  codice_azienda: (state, getters) => {
    return state.conservazioneDigitale.codice_azienda
  },
  key: (state, getters) => {
    return state.conservazioneDigitale.key
  },
  iv: (state, getters) => {
    return state.conservazioneDigitale.iv
  },
}

function formDataFromParams (params) {
  const formData = new FormData()

  if (params.username) formData.append('conservazione_digitale[username]', params.username || '')
  if (params.password) formData.append('conservazione_digitale[password]', params.password || '')
  if (params.codice_azienda) formData.append('conservazione_digitale[codice_azienda]', params.codice_azienda || '')
  if (params.key) formData.append('conservazione_digitale[key]', params.key || '')
  if (params.iv) formData.append('conservazione_digitale[iv]', params.iv || '')

  return formData
}
