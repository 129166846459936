import Vue from 'vue'
import Vuetify from '../../plugins/vuetify'

export const namespaced = true

export const state = {
  organization: {},
}

export const mutations = {
  SET_ORGANIZATION (state, organization) {
    // Questo funzione setta il tema corrente
    //  console.log(organization.whitelabel)
    state.organization = organization
    if (organization.whitelabel) {
      setVuetifyTheme(organization.theme)
    }
  },
  CLEAR_ORGANIZATION (state, current_organization) {
    state.organization = null
    setVuetifyTheme(current_organization.theme)
  },
  SET_CONSERVAZIONE_DIGITALE_A_NORMA (state, conservazione_digitale) {
    state.organization.conservazione_digitale = conservazione_digitale
  },
  SET_ORGANIZATION_PRICES (state, prices) {
    state.organization.prices = prices
  },
}

export const actions = {
  fetchOrganization ({ commit, dispatch, rootState, state }, { fresh = false }) {
    return new Promise((resolve, reject) => {
      let organization
      if (!fresh) {
        organization = state.organization
      }

      if (organization && organization.id) {
        // console.log(`organization/fetchOrganization retrieved from cache`, organization)
        resolve(organization)
      } else {
        // console.log(`organization/fetchOrganization`)
        dispatch('layout/set_loading', true, { root: true })
        Vue.http.get(rootState.application.urls.organization)
          .then(response => {
            organization = response.body
            console.log("- organization fetched successfully", organization)
            commit('SET_ORGANIZATION', organization)
            commit('SET_CONSERVAZIONE_DIGITALE_A_NORMA', response.body.conservazione_digitale)
            dispatch('layout/set_loading', false, { root: true })
            resolve(organization)
          }, error => {
            dispatch('layout/set_loading', false, { root: true })
            reject(error)
          })
      }
    })
  },
  setOrganization ({ commit }, attributes) {
    commit('SET_ORGANIZATION', attributes)
  },
  clearOrganization ({ commit, rootState }) {
    commit('CLEAR_ORGANIZATION', rootState.application.current_organization)
  },
  update ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      const formData = formDataFromParams(params)
      Vue.http.put(rootState.application.urls.organization, formData)
        .then(response => {
          commit('SET_ORGANIZATION', response.body)
          commit('SET_CONSERVAZIONE_DIGITALE_A_NORMA', response.body.conservazione_digitale)
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  updateInvoiceAccount ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      const formData = formDataFromParams(params)
      Vue.http.put(rootState.application.urls.invoice_account, formData)
        .then(response => {
          commit('SET_ORGANIZATION', response.body)
          commit('SET_CONSERVAZIONE_DIGITALE_A_NORMA', response.body.conservazione_digitale)
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  fetchPreviewEmail ({ commit, dispatch, rootState, state }) {
    return new Promise((resolve, reject) => {
      console.log(`organization/fetchPreviewEmail`)
      // dispatch('layout/set_loading', true, {root: true})
      Vue.http.get(rootState.application.urls.preview_email)
        .then(response => {
          const html = response.body?.html
          console.log(`- preview email fetched successfully`)
          // dispatch('layout/set_loading', false, {root: true})
          resolve(html)
        }, error => {
          // dispatch('layout/set_loading', false, {root: true})
          reject(error)
        })
    })
  },
  createChildOrganization({ commit, dispatch, rootState }, email) {
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.childOrganizationLinks, { email })
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  updateChildOrganization({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.childOrganizationLink.replace(':id', params.id)
      Vue.http.put(url, params.payload)
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  destroyChildOrganization({ commit, dispatch, rootState }, id) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.childOrganizationLink.replace(':id', id)
      Vue.http.delete(url)
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  sendInviteToChildOrganization({ commit, dispatch, rootState }, id) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.sendChildOrganizationLink.replace(':id', id)
      Vue.http.put(url)
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  replyToChildOrganizationInvite({ commit, dispatch, rootState }, payload) {
    let url
    if (payload.reply === true) {
      url = rootState.application.urls.acceptChildOrganizationLink
    } else {
      url = rootState.application.urls.declineChildOrganizationLink
    }
    url = url.replace(':id', payload.childCustomerLinkIdToAccept)

    return new Promise((resolve, reject) => {
      Vue.http.put(url)
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  fetchPrices({ commit, dispatch, rootState }, payload) {
    let url = rootState.application.urls.organizationPrices
    return new Promise((resolve, reject) => {
      Vue.http.get(url)
        .then(response => {
          console.log(response)
          commit('SET_ORGANIZATION_PRICES', response.body.prices)
          resolve(response)
        }, error => {
          console.log("fetchPrices error")
          console.log(error)
          reject(error)
        })
    })
  }
}

export const getters = {
  organizationName: (state, getters) => {
    return state.organization.name
  },
  isChildOrganization: (state, getters) => {
    return state.organization?.has_parent_customer === true
  },
  isStandaloneOrParentOrganization: (state, getters) => {
    return !getters.childOrganization
  },
}

function sanitizeHTML(input) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, "text/html");
  var text = doc.body.textContent || "";
  return text.replace(/https:\/\//g, "").replace(/https/g, "").replace(/http:\/\//g, "").replace(/http/g, "");
}

function formDataFromParams (params) {
  const formData = new FormData()

  if (params.name) formData.append('customer[name]', params.name || '')
  if (params.legal_first_name) formData.append('customer[legal_first_name]', params.legal_first_name || '')
  if (params.legal_last_name) formData.append('customer[legal_last_name]', params.legal_last_name || '')
  if (params.dossier_expiration_days) formData.append('customer[dossier_expiration_days]', params.dossier_expiration_days || '')

  if (params.logo) formData.append('customer[logo]', params.logo)
  if (params.email) {
    // If email is present means data arrive from organization form
    formData.append('customer[email]', params.email || '')
    // formData.append("customer[theme]", params.theme)
    formData.append('customer[primary_color]', params.primary_color || '')
    formData.append('customer[state_callback_url]', params.state_callback_url || '')
    formData.append('customer[default_redirect_url]', params.default_redirect_url || '')
    // formData.append("customer[secondary_color]"          , params.secondary_color || ""        )
    formData.append('customer[header_background_color]', params.header_background_color || '')
    formData.append('customer[header_dark]', params.header_dark)
    formData.append('customer[send_unsigned_document_by_email]', params.send_unsigned_document_by_email)
    formData.append('customer[send_unsigned_document_by_sms]', params.send_unsigned_document_by_sms)
    formData.append('customer[send_signed_document_by_email]', params.send_signed_document_by_email)
    formData.append('customer[send_signed_document_by_sms]', params.send_signed_document_by_sms)
    formData.append('customer[email_for_recognition_docs]', params.email_for_recognition_docs)
    formData.append('customer[generate_signed_document]', params.generate_signed_document)
    formData.append('customer[otp_via_sms_allowed]', params.otp_via_sms_allowed)
    formData.append('customer[otp_via_email_allowed]', params.otp_via_email_allowed)
    formData.append('customer[support_email]', params.support_email || '')
    formData.append('customer[percentage_low_credit]', params.percentage_low_credit || '')
    formData.append('customer[send_signed_documents_to_customer]', params.send_signed_documents_to_customer)
    formData.append('customer[sms_provider_first]', params.sms_provider_first)
    formData.append('customer[sms_provider_second]', params.sms_provider_second)
    formData.append('customer[email_note]', sanitizeHTML(params.email_note).trim())
  }
  if (params.invoice_account) {
    formData.append('invoice_account[business_name]', params.invoice_account.business_name || '')
    formData.append('invoice_account[account_type]', params.invoice_account.account_type || '')
    formData.append('invoice_account[country_id]', params.invoice_account.country_id || '')
    formData.append('invoice_account[region_id]', params.invoice_account.region_id || '')
    formData.append('invoice_account[city_id]', params.invoice_account.city_id || '')
    formData.append('invoice_account[address]', params.invoice_account.address || '')
    formData.append('invoice_account[zip]', params.invoice_account.zip || '')
    formData.append('invoice_account[vat_number]', params.invoice_account.vat_number || '')
    formData.append('invoice_account[fiscal_code]', params.invoice_account.fiscal_code || '')
    formData.append('invoice_account[email]', params.invoice_account.email || '')
    formData.append('invoice_account[pec]', params.invoice_account.pec || '')
    formData.append('invoice_account[sdi_code]', params.invoice_account.sdi_code || '')
  }

  return formData
}

function setVuetifyTheme (theme) {
  if (theme?.dark_mode) {
    Vuetify.framework.theme.dark = true
    if (theme.primary_color) Vuetify.framework.theme.themes.dark.primary = theme.primary_color
    if (theme.secondary_color) Vuetify.framework.theme.themes.dark.secondary = theme.secondary_color
    if (theme.accent_color) Vuetify.framework.theme.themes.dark.accent = theme.accent_color
    // NB: temporary removed custom semantic colors
    if (theme.info_color)       Vuetify.framework.theme.themes.dark.info = theme.info_color
    if (theme.success_color)    Vuetify.framework.theme.themes.dark.success = theme.success_color
    if (theme.error_color)      Vuetify.framework.theme.themes.dark.error = theme.error_color
    if (theme.warning_color)    Vuetify.framework.theme.themes.dark.warning = theme.warning_color
  } else {
    Vuetify.framework.theme.light = true
    if (theme.primary_color) Vuetify.framework.theme.themes.light.primary = theme.primary_color
    if (theme.secondary_color) Vuetify.framework.theme.themes.light.secondary = theme.secondary_color
    if (theme.accent_color) Vuetify.framework.theme.themes.light.accent = theme.accent_color
    // NB: temporary removed custom semantic colors
    if (theme.info_color)       Vuetify.framework.theme.themes.light.info = theme.info_color
    if (theme.success_color)    Vuetify.framework.theme.themes.light.success = theme.success_color
    if (theme.error_color)      Vuetify.framework.theme.themes.light.error = theme.error_color
    if (theme.warning_color)    Vuetify.framework.theme.themes.light.warning = theme.warning_color
  }
}
